const englishConfig = {
  addr: {
    name: "Manikganj Islamia Kamil(M A) Madrasah",
    local: "Sadar, Manikganj",
    est: "Est-1953",
    code: "EIIN-110989"
     
  },
  email: "kamilmadrasamanikganj@gmail.com",
  phone:"",
  contact:"Contact: 01712644766",

  chairmanNm:"Dr. Manwar Hossain Molla",  
  chairDesig:"Chairman",
  chairDetail:"D C(Manikganj)",

  principalNm:"Prof. Md Atiqur Rahman",
  principalDesig:"Principal",
  principalJobId:""

};
export default englishConfig;

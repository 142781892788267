import React, { useRef } from "react";
import Slider from "react-slick";
import { Button } from "reactstrap";

import slide1 from '../../assets/img/slider/active/1.jpg';
import slide2 from '../../assets/img/slider/active/2.jpg';
import slide3 from '../../assets/img/slider/active/3.jpg';
import slide4 from '../../assets/img/slider/active/4.jpg';
import slide5 from '../../assets/img/slider/active/5.jpg';

function Banner() {
  let sliderRef = useRef(null);
  const play = () => {
    sliderRef.slickPlay();
  };
  const pause = () => {
    sliderRef.slickPause();
  };

  const settings = {
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000
  };
  return (
    <div className="container">
      <div className="row">
        <div className="col-lg-12">
    <div className="slider-container">
      
      <Slider ref={slider => (sliderRef = slider)} {...settings}>
        <div>
          <img src={slide1}/>
        </div>
        <div>
        <img src={slide2}/>
        </div>
        <div>
        <img src={slide3}/>
        </div>
        <div>
        <img src={slide4}/>
        </div>
        <div>
        <img src={slide5}/>
        </div>
         
      </Slider>
      <div  style={{ textAlign: "right"}}>
        <Button   color="success"  outline  size="sm" onClick={play}>
          Play
        </Button>
         
        <Button  color="info"  outline  size="sm"  onClick={pause}>
          Pause
        </Button>
      </div>
    </div>
    </div>
    </div>
    </div>
  );
}
export default Banner;
import React, { Component } from "react";
import { Link } from "react-router-dom";
import Social from "../section-components/social";
import englishConfig from "../../lang/englishConfig";
import "../../assets/css/headerCustom.css";
class NavbarV5 extends Component {
  render() {
    let publicUrl = process.env.PUBLIC_URL + "/";
    return (
      <div>
        {/* HEADER AREA START (header-3) */}
        <header className="ltn__header-area ltn__header-3 section-bg-6---">
          <div className="ltn__header-top-area section-bg-6 top-area-color-white---">
            <div className="container">
              <div className="row">
                <div className="col-md-9">
                  <div className="ltn__top-bar-menu">
                    <ul>
                      <li>
                        <i className="icon-mail" /> {englishConfig.email}{" "}
                      </li>

                      <li>
                        <i className="fa fa-phone-square" />{" "}
                        {englishConfig.contact}{" "}
                      </li>
                      <li>
                        <i className="icon-placeholder" />{" "}
                        {englishConfig.addr.local}{" "}
                      </li>
                      <li>
                        <i className="fa fa-info" /> {englishConfig.addr.est}
                        {". "}
                        {englishConfig.addr.code}
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="top-bar-right text-end">
                    <div className="ltn__top-bar-menu">
                      <ul>
                        <li>
                          {/* header-top-btn */}
                          <div className="header-top-btn">
                            <Link to="/add-listing">Online Application</Link>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* ltn__header-middle-area start */}
          <div className="ltn__header-middle-area">
            <div className="container">
              <div className="row">
                <div className="col-lg-2 col-md-2 col-sm-2">
                  <div className="site-logo ">
                    <Link to="/">
                      <img  className="logImg" src={publicUrl + "assets/img/logo.png"} alt="Logo" />
                    </Link>
                  </div>
                </div>
                <div className="col-lg-10 col-md-10 col-sm-10">
                  <div className="row">
                    <h1 className="textName">{englishConfig.addr.name}</h1>{" "}
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* ltn__header-middle-area end */}


          {/* header-bottom-area start */}
          <div className="header-bottom-area ltn__border-top ltn__header-sticky  ltn__sticky-bg-white ltn__primary-bg---- menu-color-white---- d-none d-lg-block">
            <div className="container">
              <div className="row">
                <div className="col header-menu-column">
                  <div className="sticky-logo">					 
							     <Link to="/"><img className="stickylogImg" src={publicUrl+"assets/img/logo.png"} alt="Logo" /></Link>						 
						      </div>
                  <div className="header-menu-2">
                    <nav>
                      <div className="ltn__main-menu">
                        <ul>
                          <li>
                            <Link to="/">Home</Link>{" "}
                          </li>
                          <li className="menu-icon">
                            <Link to="/about">About</Link>
                            <ul>
                              <li>
                                <Link to="/about">At a glance</Link>
                              </li>
                              <li>
                                <Link to="/service">History</Link>
                              </li>
                              <li>
                                <Link to="/service-details">
                                  Why study at GCC
                                </Link>
                              </li>
                              <li>
                                <Link to="/portfolio">Mission and Vision</Link>
                              </li>
                              <li>
                                <Link to="/portfolio-v2">
                                  Rules & Regulation{" "}
                                </Link>
                              </li>
                            </ul>
                          </li>
                          <li className="menu-icon">
                            <Link to="/shop">Administration </Link>
                            <ul>
                              <li>
                                <Link to="/shop">Message from Principal</Link>
                              </li>
                              <li>
                                <Link to="/shop-grid">Governing body</Link>
                              </li>
                              <li>
                                <Link to="/shop-left-sidebar">
                                  Teachers Info
                                </Link>
                              </li>
                              <li>
                                <Link to="/shop-right-sidebar">
                                  Staffs Info
                                </Link>
                              </li>
                              <li>
                                <Link to="/product-details">
                                  PTA (Parents Teachers Association)
                                </Link>
                              </li>
                            </ul>
                          </li>
                          <li className="menu-icon">
                            <Link to="/blog-grid">Admission </Link>
                            <ul>
                              <li>
                                <Link to="/blog">Admission Notice</Link>
                              </li>
                              <li>
                                <Link to="/blog-grid">Admission Form</Link>
                              </li>
                              <li>
                                <Link to="/blog-left-sidebar">
                                  Selected List
                                </Link>
                              </li>
                              <li>
                                <Link to="/blog-right-sidebar">
                                  Fees & Payments
                                </Link>
                              </li>
                              <li>
                                <Link to="/blog-details">Payment Method</Link>
                              </li>
                            </ul>
                          </li>
                          <li className="menu-icon">
                            <Link to="#">Academic </Link>
                            <ul className="mega-menu">
                              <li>
                                <a href="#">HSC</a>
                                <ul>
                                  <li>
                                    <Link to="/portfolio">Science</Link>
                                  </li>
                                  <li>
                                    <Link to="/portfolio-v2">Huminites</Link>
                                  </li>
                                  <li>
                                    <Link to="/portfolio-details">
                                      Business Studies
                                    </Link>
                                  </li>
                                </ul>
                              </li>
                              <li>
                                <Link to="#">Degree Pass</Link>
                                <ul>
                                  <li>
                                    <Link to="/history">First Yr</Link>
                                  </li>
                                  <li>
                                    <Link to="/add-listing">2nd Yr</Link>
                                  </li>
                                  <li>
                                    <Link to="/location">Preli MS</Link>
                                  </li>
                                </ul>
                              </li>
                              <li>
                                <Link to="#">Hons</Link>
                                <ul>
                                  <li>
                                    <Link to="/shop">Bangla</Link>
                                  </li>
                                  <li>
                                    <Link to="/shop-left-sidebar">English</Link>
                                  </li>
                                  <li>
                                    <Link to="/shop-right-sidebar">
                                      Accounting
                                    </Link>
                                  </li>
                                  <li>
                                    <Link to="/shop-grid">Physics</Link>
                                  </li>
                                </ul>
                              </li>
                              <li>
                                <Link to="#">Related</Link>
                                <ul>
                                  <li>
                                    <Link to="/shop-left-sidebar">
                                      Holidays List
                                    </Link>
                                  </li>
                                  <li>
                                    <Link to="/shop-right-sidebar">
                                      Annual Plan
                                    </Link>
                                  </li>
                                  <li>
                                    <Link to="/shop-grid">Achievements</Link>
                                  </li>
                                  <li>
                                    <Link to="/product-details">
                                      Co-Curricular Activities
                                    </Link>
                                  </li>
                                </ul>
                              </li>
                            </ul>
                          </li>
                          <li>
                            <Link to="/contact">Evaluation & Result</Link>
                            <ul>
                              <li>
                                <Link to="/shop">Exam Systems</Link>
                              </li>
                              <li>
                                <Link to="/shop-left-sidebar">
                                  Evaluation Method
                                </Link>
                              </li>
                              <li>
                                <Link to="/shop-right-sidebar">
                                  Internal Result
                                </Link>
                              </li>
                              <li>
                                <Link to="/shop-grid">Board Result</Link>
                              </li>
                              <li>
                                <Link to="/product-details">
                                  Certificate Check{" "}
                                </Link>
                              </li>
                              <li>
                                <Link to="/cart">Reexamine Application </Link>
                              </li>
                            </ul>
                          </li>

                          <li>
                            <Link to="/contact">Facilities</Link>
                            <ul>
                              <li>
                                <Link to="/shop">Hostel</Link>
                              </li>
                              <li>
                                <Link to="/shop-left-sidebar">Library</Link>
                              </li>
                              <li>
                                <Link to="/shop-right-sidebar">
                                  Computer Lab
                                </Link>
                              </li>
                              <li>
                                <Link to="/shop-grid">Language Club</Link>
                              </li>
                              <li>
                                <Link to="/product-details">Sports Club</Link>
                              </li>
                              <li>
                                <Link to="/cart">Girl's Common Room </Link>
                              </li>
                            </ul>
                          </li>

                          <li>
                            <Link to="/contact">Notice </Link>
                            <ul>
                              <li>
                                <Link to="/shop">Student Notice</Link>
                              </li>
                              <li>
                                <Link to="/shop-left-sidebar">
                                  Teacher Notice
                                </Link>
                              </li>
                              <li>
                                <Link to="/shop-right-sidebar">
                                  Recruitment Notice
                                </Link>
                              </li>
                            </ul>
                          </li>

                          <li>
                            <Link to="/contact">Alumni</Link>
                            <ul>
                              <li>
                                <Link to="/shop">Registration</Link>
                              </li>
                              <li>
                                <Link to="/shop">Alumni Members</Link>
                              </li>
                              <li>
                                <Link to="/shop-left-sidebar">
                                  Previous Students
                                </Link>
                              </li>
                            </ul>
                          </li>
                        </ul>
                      </div>
                    </nav>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* header-bottom-area end */}
        </header>
        {/* HEADER AREA END */}

        <div
          id="ltn__utilize-mobile-menu"
          className="ltn__utilize ltn__utilize-mobile-menu"
        >
          <div className="ltn__utilize-menu-inner ltn__scrollbar">
            <div className="ltn__utilize-menu-head">
              <div className="site-logo">
                <Link to="/">
                  <img src={publicUrl + "assets/img/logo.png"} alt="Logo" />
                </Link>
              </div>
              <button className="ltn__utilize-close">×</button>
            </div>

            <div className="ltn__utilize-menu">
            <ul>
                          <li>
                            <Link to="/">Home</Link>{" "}
                          </li>
                          <li className="menu-icon">
                            <Link to="/about">About</Link>
                            <ul>
                              <li>
                                <Link to="/about">At a glance</Link>
                              </li>
                              <li>
                                <Link to="/service">History</Link>
                              </li>
                              <li>
                                <Link to="/service-details">
                                  Why study at GCC
                                </Link>
                              </li>
                              <li>
                                <Link to="/portfolio">Mission and Vision</Link>
                              </li>
                              <li>
                                <Link to="/portfolio-v2">
                                  Rules & Regulation{" "}
                                </Link>
                              </li>
                            </ul>
                          </li>
                          <li className="menu-icon">
                            <Link to="/shop">Administration </Link>
                            <ul>
                              <li>
                                <Link to="/shop">Message from Principal</Link>
                              </li>
                              <li>
                                <Link to="/shop-grid">Governing body</Link>
                              </li>
                              <li>
                                <Link to="/shop-left-sidebar">
                                  Teachers Info
                                </Link>
                              </li>
                              <li>
                                <Link to="/shop-right-sidebar">
                                  Staffs Info
                                </Link>
                              </li>
                              <li>
                                <Link to="/product-details">
                                  PTA (Parents Teachers Association)
                                </Link>
                              </li>
                            </ul>
                          </li>
                          <li className="menu-icon">
                            <Link to="/blog-grid">Admission </Link>
                            <ul>
                              <li>
                                <Link to="/blog">Admission Notice</Link>
                              </li>
                              <li>
                                <Link to="/blog-grid">Admission Form</Link>
                              </li>
                              <li>
                                <Link to="/blog-left-sidebar">
                                  Selected List
                                </Link>
                              </li>
                              <li>
                                <Link to="/blog-right-sidebar">
                                  Fees & Payments
                                </Link>
                              </li>
                              <li>
                                <Link to="/blog-details">Payment Method</Link>
                              </li>
                            </ul>
                          </li>
                          <li className="menu-icon">
                            <Link to="#">Academic </Link>
                            <ul className="mega-menu">
                              <li>
                                <a href="#">HSC</a>
                                <ul>
                                  <li>
                                    <Link to="/portfolio">Science</Link>
                                  </li>
                                  <li>
                                    <Link to="/portfolio-v2">Huminites</Link>
                                  </li>
                                  <li>
                                    <Link to="/portfolio-details">
                                      Business Studies
                                    </Link>
                                  </li>
                                </ul>
                              </li>
                              <li>
                                <Link to="#">Degree Pass</Link>
                                <ul>
                                  <li>
                                    <Link to="/history">First Yr</Link>
                                  </li>
                                  <li>
                                    <Link to="/add-listing">2nd Yr</Link>
                                  </li>
                                  <li>
                                    <Link to="/location">Preli MS</Link>
                                  </li>
                                </ul>
                              </li>
                              <li>
                                <Link to="#">Hons</Link>
                                <ul>
                                  <li>
                                    <Link to="/shop">Bangla</Link>
                                  </li>
                                  <li>
                                    <Link to="/shop-left-sidebar">English</Link>
                                  </li>
                                  <li>
                                    <Link to="/shop-right-sidebar">
                                      Accounting
                                    </Link>
                                  </li>
                                  <li>
                                    <Link to="/shop-grid">Physics</Link>
                                  </li>
                                </ul>
                              </li>
                              <li>
                                <Link to="#">Related</Link>
                                <ul>
                                  <li>
                                    <Link to="/shop-left-sidebar">
                                      Holidays List
                                    </Link>
                                  </li>
                                  <li>
                                    <Link to="/shop-right-sidebar">
                                      Annual Plan
                                    </Link>
                                  </li>
                                  <li>
                                    <Link to="/shop-grid">Achievements</Link>
                                  </li>
                                  <li>
                                    <Link to="/product-details">
                                      Co-Curricular Activities
                                    </Link>
                                  </li>
                                </ul>
                              </li>
                            </ul>
                          </li>
                          <li>
                            <Link to="/contact">Evaluation & Result</Link>
                            <ul>
                              <li>
                                <Link to="/shop">Exam Systems</Link>
                              </li>
                              <li>
                                <Link to="/shop-left-sidebar">
                                  Evaluation Method
                                </Link>
                              </li>
                              <li>
                                <Link to="/shop-right-sidebar">
                                  Internal Result
                                </Link>
                              </li>
                              <li>
                                <Link to="/shop-grid">Board Result</Link>
                              </li>
                              <li>
                                <Link to="/product-details">
                                  Certificate Check{" "}
                                </Link>
                              </li>
                              <li>
                                <Link to="/cart">Reexamine Application </Link>
                              </li>
                            </ul>
                          </li>

                          <li>
                            <Link to="/contact">Facilities</Link>
                            <ul>
                              <li>
                                <Link to="/shop">Hostel</Link>
                              </li>
                              <li>
                                <Link to="/shop-left-sidebar">Library</Link>
                              </li>
                              <li>
                                <Link to="/shop-right-sidebar">
                                  Computer Lab
                                </Link>
                              </li>
                              <li>
                                <Link to="/shop-grid">Language Club</Link>
                              </li>
                              <li>
                                <Link to="/product-details">Sports Club</Link>
                              </li>
                              <li>
                                <Link to="/cart">Girl's Common Room </Link>
                              </li>
                            </ul>
                          </li>

                          <li>
                            <Link to="/contact">Notice </Link>
                            <ul>
                              <li>
                                <Link to="/shop">Student Notice</Link>
                              </li>
                              <li>
                                <Link to="/shop-left-sidebar">
                                  Teacher Notice
                                </Link>
                              </li>
                              <li>
                                <Link to="/shop-right-sidebar">
                                  Recruitment Notice
                                </Link>
                              </li>
                            </ul>
                          </li>

                          <li>
                            <Link to="/contact">Alumni</Link>
                            <ul>
                              <li>
                                <Link to="/shop">Registration</Link>
                              </li>
                              <li>
                                <Link to="/shop">Alumni Members</Link>
                              </li>
                              <li>
                                <Link to="/shop-left-sidebar">
                                  Previous Students
                                </Link>
                              </li>
                            </ul>
                          </li>
                        </ul>
            </div>
 
          </div>
        </div>
      </div>
    );
  }
}

export default NavbarV5;

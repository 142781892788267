import React from 'react';
import Navbar from './global-components/navbar';
import Banner from './section-components/Banner';
import Mission from './section-components/missionVision';
import HomeBody from '../components/section-components/homebody'
 
import Footer from './global-components/footer';

const Home_V11 = () => {
    return <div>
        <Navbar/> 
        <Banner /> 
        <Mission/>     
        <HomeBody/> 
        <Footer />
    </div>
}

export default Home_V11


import React, { Component } from 'react';
import { Link } from 'react-router-dom';


class Social extends Component {

    render() {

        let publicUrl = process.env.PUBLIC_URL+'/'

    return <div className="ltn__social-media">
			<ul>
				<li><a href="https://www.facebook.com/tuna-theme" title="Facebook"><i className="fab fa-facebook-f" /></a></li>
		 
			</ul>
		</div>
        }
}

export default Social
import React, { Component } from 'react';
import { Link } from 'react-router-dom';



class homebodySidebar extends Component {
	render() {
		let anchor = '#'

		let publicUrl = process.env.PUBLIC_URL + '/'
		return (
			<div className="col-lg-4 go-top">
				<aside className="sidebar-area blog-sidebar ltn__right-sidebar">
					{/* Popular Post Widget */}
					<div className="widget ltn__popular-post-widget">
						<div style={{display:"flex", gap:"50px"}}>
													<h4 className="ltn__widget-title ltn__widget-title-border-2">Leatest Notice</h4>
													<Link to="#"><i className="fas fa-search" />Find All</Link>
					 
						</div>

						<ul>
							<li>
								<div className="popular-post-widget-item clearfix">
									<div className="popular-post-widget-img">
										<Link to="/blog-details"><img src={publicUrl + "assets/img/team/5.jpg"} alt="#" /></Link>
									</div>
									<div className="popular-post-widget-brief">
										<h6><Link to="/blog-details">Lorem ipsum dolor sit
											cing elit, sed do.</Link></h6>
										<div className="ltn__blog-meta">
											<ul>
												<li className="ltn__blog-date">
													<Link to="#"><i className="far fa-calendar-alt" />June 22, 2020</Link>
												</li>
											</ul>
										</div>
									</div>
								</div>
							</li>
							<li>
								<div className="popular-post-widget-item clearfix">
									<div className="popular-post-widget-img">
										<Link to="/blog-details"><img src={publicUrl + "assets/img/team/6.jpg"} alt="#" /></Link>
									</div>
									<div className="popular-post-widget-brief">
										<h6><Link to="/blog-details">Lorem ipsum dolor sit
											cing elit, sed do.</Link></h6>
										<div className="ltn__blog-meta">
											<ul>
												<li className="ltn__blog-date">
													<Link to="#"><i className="far fa-calendar-alt" />June 22, 2020</Link>
												</li>
											</ul>
										</div>
									</div>
								</div>
							</li>
							<li>
								<div className="popular-post-widget-item clearfix">
									<div className="popular-post-widget-img">
										<Link to="/blog-details"><img src={publicUrl + "assets/img/team/7.jpg"} alt="#" /></Link>
									</div>
									<div className="popular-post-widget-brief">
										<h6><Link to="/blog-details">Lorem ipsum dolor sit
											cing elit, sed do.</Link></h6>
										<div className="ltn__blog-meta">
											<ul>
												<li className="ltn__blog-date">
													<Link to="#"><i className="far fa-calendar-alt" />June 22, 2020</Link>
												</li>
											</ul>
										</div>
									</div>
								</div>
							</li>
							<li>
								<div className="popular-post-widget-item clearfix">
									<div className="popular-post-widget-img">
										<Link to="/blog-details"><img src={publicUrl + "assets/img/team/8.jpg"} alt="#" /></Link>
									</div>
									<div className="popular-post-widget-brief">
										<h6><Link to="/blog-details">Lorem ipsum dolor sit
											cing elit, sed do.</Link></h6>
										<div className="ltn__blog-meta">
											<ul>
												<li className="ltn__blog-date">
													<Link to="#"><i className="far fa-calendar-alt" />June 22, 2020</Link>
												</li>
											</ul>
										</div>
									</div>
								</div>
							</li>
						</ul>
					</div>
					{/* Search Widget */}
					<div className="widget ltn__search-widget">
						<h4 className="ltn__widget-title ltn__widget-title-border-2">Online Education</h4>
						<form action="#">
							{/* <input type="text" name="search" placeholder="Search your keyword..." /> */}
							<button type="submit"className='btn theme-btn-1'>Learn from Any Where</button>
						</form>
					</div>

					{/* Popular Post Widget (Twitter Post) */}
					<div className="widget ltn__popular-post-widget ltn__twitter-post-widget">
						<h4 className="ltn__widget-title ltn__widget-title-border-2">Important Contact</h4>
						<ul>
							<li>
								<div className="popular-post-widget-item clearfix">
									<div className="popular-post-widget-img">
										<Link to="/blog-details"><i className="fab fa-twitter" /></Link>
									</div>
									<div className="popular-post-widget-brief">
										<p>Carsafe - #Gutenberg ready
											@wordpress
											Theme for Car Service, Auto Parts, Car Dealer available on
											@website
											<a href="https://website.net">https://website.net</a></p>
										<div className="ltn__blog-meta">
											<ul>
												<li className="ltn__blog-date">
													<Link to="#"><i className="far fa-calendar-alt" />June 22, 2020</Link>
												</li>
											</ul>
										</div>
									</div>
								</div>
							</li>
							<li>
								<div className="popular-post-widget-item clearfix">
									<div className="popular-post-widget-img">
										<a href="blog-details.html"><i className="fab fa-twitter" /></a>
									</div>
									<div className="popular-post-widget-brief">
										<p>Carsafe - #Gutenberg ready
											@wordpress
											Theme for Car Service, Auto Parts, Car Dealer available on
											@website
											<a href="https://website.net">https://website.net</a></p>
										<div className="ltn__blog-meta">
											<ul>
												<li className="ltn__blog-date">
													<Link to="#"><i className="far fa-calendar-alt" />June 22, 2020</Link>
												</li>
											</ul>
										</div>
									</div>
								</div>
							</li>
							<li>
								<div className="popular-post-widget-item clearfix">
									<div className="popular-post-widget-img">
										<Link to="/blog-details"><i className="fab fa-twitter" /></Link>
									</div>
									<div className="popular-post-widget-brief">
										<p>Carsafe - #Gutenberg ready
											@wordpress
											Theme for Car Service, Auto Parts, Car Dealer available on
											@website
											<a href="https://website.net">https://website.net</a></p>
										<div className="ltn__blog-meta">
											<ul>
												<li className="ltn__blog-date">
													<Link to="#"><i className="far fa-calendar-alt" />June 22, 2020</Link>
												</li>
											</ul>
										</div>
									</div>
								</div>
							</li>
						</ul>
					</div>
										{/* Menu Widget (Category) */}
										<div className="widget ltn__menu-widget ltn__menu-widget-2--- ltn__menu-widget-2-color-2--- go-top">
						<h4 className="ltn__widget-title ltn__widget-title-border-2">Useful Links</h4>
						<ul>
							<li><span><Link to="/blog-grid"><i class="fa fa-spinner fa-spin"></i>National University </Link></span><Link to="/blog-grid"><i class="fa fa-spinner fa-spin"></i>Dhaka University </Link></li>
							<li><span></span><Link to="/blog-grid">Education Board </Link></li>
							<li><span></span><Link to="/blog-grid">PM Office  </Link></li>
							<li><span></span><Link to="/blog-grid">Police  </Link></li>
							<li><span></span><Link to="/blog-grid">Passport Office </Link></li>
						</ul>
					</div>
 

					{/* Form Widget */}
					<div className="widget ltn__form-widget">
						<h4 className="ltn__widget-title ltn__widget-title-border-2">Ask What You Need</h4>
						<form action="#">
							<input type="text" name="yourname" placeholder="Your Name*" />
							<input type="text" name="youremail" placeholder="Your e-Mail*" />
							<textarea name="yourmessage" placeholder="Write Message..." defaultValue={""} />
							<button type="submit" className="btn theme-btn-1">Send Messege</button>
						</form>
					</div>
				 
					{/* Social Media Widget */}
					<div className="widget ltn__social-media-widget">
						<h4 className="ltn__widget-title ltn__widget-title-border-2">Follow us</h4>
						<div className="ltn__social-media-2">
							<ul>
								<li><a href="#" title="Facebook"><i className="fab fa-facebook-f" /></a></li>
								<li><a href="#" title="Twitter"><i className="fab fa-twitter" /></a></li>
								<li><a href="#" title="Linkedin"><i className="fab fa-linkedin" /></a></li>
								<li><a href="#" title="Instagram"><i className="fab fa-instagram" /></a></li>
							</ul>
						</div>
					</div>
			 
					{/* Banner Widget */}
					<div className="widget ltn__banner-widget d-none go-top">
						<Link to="/shop"><img src={publicUrl + "assets/img/banner/2.jpg"} alt="#" /></Link>
					</div>
				</aside>
			</div>
		)
	}
}

export default homebodySidebar;

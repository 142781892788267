import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import dcImg from '../../assets/img/team/principal/dc.jpg';
import principalImg from '../../assets/img/team/principal/principal.jpg';
import missvis from '../../assets/img/missionvision/mission-and-vision.png';
import englishConfig from "../../lang/englishConfig";
class missionVision extends Component {

    render() {

        let publicUrl = process.env.PUBLIC_URL+'/'
        

    return <div className="ltn__category-area ltn__product-gutter section-bg-1--- pt-15 pb-10 go-top">
			  <div className="container"> 
			    <div className="row ltn__category-slider-active--- slick-arrow-1 justify-content-center">
			      <div className="col-lg-3 col-md-3 col-sm-12">
			        <div className="ltn__category-item ltn__category-item-5 text-center">
			          <Link to="/#">
			            <span ><img className="category-icon" src={dcImg} /> </span>
			            <span className="category-title">{englishConfig.chairDesig}</span>
						<h6>{englishConfig.chairmanNm}</h6>
						<span style={{fontSize:"10px"}}>{englishConfig.chairDetail}</span>
			            <span className="category-btn"><i className="flaticon-right-arrow" /></span>
			          </Link>
			        </div>
			      </div>
			      <div className="col-lg-6 col-md-6 col-sm-12">
			        <div className="ltn__category-item ltn__category-item-5 text-center">
			          <Link to="/#">
			            
			            <span className="category-title">Mission & Vision</span>
						<span> <img src={missvis} /></span>
			            <span className="category-btn"><i className="flaticon-right-arrow" /></span>
			          </Link>
			        </div>
			      </div>
			      <div className="col-lg-3 col-md-3 col-sm-12">
			        <div className="ltn__category-item ltn__category-item-5 text-center">
			          <Link to="/#">
			            <span ><img className="category-icon" src={principalImg} /></span>
			            <span className="category-title">{englishConfig.principalDesig}</span>
						<h6>{englishConfig.principalNm}</h6>
						<span style={{fontSize:"10px"}}>{englishConfig.principalJobId}</span>
			            <span className="category-btn"><i className="flaticon-right-arrow" /></span>
			          </Link>
			        </div>
			      </div>
		  
			    </div>
			  </div>
			</div>
        }
}

export default missionVision